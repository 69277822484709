<template>
  <div class="row m-0 mt-md-5 mb-md-5">
    <div class="col-md-3">
      <div class="card-header pt-5 pb-5 h3 animate__animated animate__slideInDown">新闻发布</div>
      <div class="list-group p-0">
        <a href="#" class="list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">政策法规</a>
        <a href="#" class="list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">企业动态</a>
        <a href="#" class="list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">专利相关</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="table-responsive pb-3 pt-3" v-for="(newList,index) in newsList" v-bind:key="index">
        <div class="card border-top-0 border-left-0 border-right-0">
          <a href="javascript:(0);" class="text-dark" @click="content(newList.newId)">
            <div class="float-left pl-4">{{ newList.newTitle }}</div>
            <div class="float-right small align-self-center text-right pr-5">{{ newList.newDate }}</div>
          </a>
        </div>
      </div>
      <div class="container text-center mt-5">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#">首页</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item active"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">下一页</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newlist",
  data() {
    return {
      newsList: ''
    }
  },
  mounted() {
    this.getNews(0)
  },
  methods: {
    getNews(index) {
      this.$http({
        method: "get",
        url: "/api/reception/getNews/" + index,
        data: {}
      }).then((res) => {
        var news = res.data
        this.newsList = news.data
      })
    },
    content(newId) {
      this.$router.push({
        path: '/con/',
        query: {newId: newId}
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>